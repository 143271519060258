import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import loader from '../assets/images/770.gif';
import $ from 'jquery';
import { api_url } from '../Config';
import Header from './Header';
import swal from 'sweetalert';
import Footer from './Footer';
import CustomerHeader from '../component/CustomerHeader';
import Carousel from 'react-bootstrap/Carousel';

import Slidimages from '../component/Slidimages';
import { Collapse } from 'react-bootstrap';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      redirectToReferrer: false,
      base_url: api_url.base_url,
      email: '',
      password: '',
      allStatus: [],
      'no_record': false,
      'loading': false,
      'pendingcod': 0,
      'totalcod': 0,
      goToOrder: false,
      translation_array: [],
      lang_array: [],
      advertisements: [],
      news: {},
      todayOrders: [],
      todayOrdersSelected: false,
      youtubeTutorialLink: '',
    };
    this.todayOrderRef = null;
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name == null) {
      localStorage.setItem('lang', JSON.stringify(this.state.english));
      localStorage.setItem('lang_name', 'english');
    }
    this.onChange = this.onChange.bind(this);
    this.Logout = this.Logout.bind(this);
    this.toggleTodayOrders = this.toggleTodayOrders.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    let lang_name = localStorage.getItem('lang_name');
    let customerData = {
      customer_id: localStorage.getItem('customer_id'),
      'lang': lang_name
    }
    const addUrl = this.state.base_url + 'customer_api.php?type=getDashboardData';
    fetch(addUrl,
      {

        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerData)
      })
      .then((response) => response.json()
        .then((result) => {
          if (result.response == 1) {
            this.setState({
              allStatus: result.status,
              totalcod: result.total_cod,
              pendingcod: result.pending_cod,
              'lang_array': result.translation,
              advertisements: result.advertisements,
              news: result.news,
              loading: false,
              youtubeTutorialLink: result.youtubeTutorialLink,
            })
            localStorage.setItem("trnas_array", JSON.stringify(this.state.lang_array));
          }
        }))
      .catch((error) => {
        this.setState({ loading: false });
      });
      fetch(this.state.base_url + 'customer_api.php?type=getDashboardDataTodayOrders',
        {
  
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(customerData)
        })
        .then((response) => response.json()
          .then((result) => {
            if (result.response == 1) {
              this.setState({
                todayOrders: result.todayOrderStatus,
              })
            }
          }))
        .catch((error) => {
          this.setState({ loading: false });
        });
  
  }

  componentWillMount() {

    if (localStorage.getItem("customer_id")) {
      this.getUserData();
    }
    else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(localStorage.getItem("customer_id"));

  }

  Logout() {
    console.log('in logout');
    localStorage.clear();
    console.log(localStorage)
  }

  moveToOrders(status) {
    sessionStorage.setItem('search_status', status);
    this.setState({ goToOrder: true })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  toggleTodayOrders() {
    this.setState({
      todayOrdersSelected: !this.state.todayOrdersSelected,
    })
    // window.scrollTo(0.)
  }
  render() {
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name == null) {
      localStorage.setItem('lang', JSON.stringify(this.state.english));
      localStorage.setItem('lang_name', 'english');
    }
    const lang = JSON.parse(localStorage.getItem("lang"));
    const lang_array = this.state.lang_array ? this.state.lang_array : [];
    if (this.state.goToOrder) {
      return (<Redirect to={{pathname: `orders/`, search: `?status=${sessionStorage.getItem("search_status")}`}} />)
    }
    if (this.state.redirectToReferrer) {
      return (<Redirect to='from-me-shipment' />)
    }
    const preventDefault = (event) => {
      event.preventDefault();
      // Your additional logic here
    };
    return (

      <React.Fragment>
        <div id="page-name" className={'' + ((lang_name != 'english' ? 'direction-rtl' : 'direction-ltr'))} style={{ direction: (lang_name != 'english' ? 'rtl' : 'ltr') }}>
          <div className="booking_values main_body register_main track_shipment_page" id="desktop_view">
            <div className="login_screen">




              <CustomerHeader title="Dashboard" />
              {this.state.loading ? <img className="loader_onorder" src={loader} alt="Loading data" /> :
                <React.Fragment>
                  {this.state.todayOrdersSelected ? <Collapse in={this.state.todayOrdersSelected} timeout={500}  >
                    <div>
                      <div className="top_set_new">

                        <div className="dashb_icons custom"><ul onClick={this.toggleTodayOrders}>
                          <li className="w-100 bg-warning"><a onClick={preventDefault} href="#" className="new" style={{justifyContent: 'initial'}}>
                          <i ref={this.todayOrderRef} class={"fa fa-arrow-left"}> </i><span>{lang.todayRecordedOrders}</span>
                          </a></li>
                        </ul></div>


                      </div>
                      <div className="shipment_box_new contact_page dashboard_page today-orders mb-2">
                        <p>
                          <b>{lang.dashboard}</b> {lang.lets_get_a_quick_today}
                        </p>
                        <ul>
                          {this.state.todayOrders.map((value, index) => {
                            var status_name = value.status_name ? value.status_name : '';

                            lang_array.length > 0 && lang_array.map(trnas_val => {
                              if (trnas_val.keyword.toLowerCase() == status_name.toLowerCase()) {
                                status_name = trnas_val.translation;
                              }
                            })

                            return (
                              <Link key={index} onClick={(e) => this.moveToOrders(value.status_name)}>
                                <li >
                                  <a key={index} className="service_bg">
                                    <svg viewBox="0 0 24 24"><path d="M4.996 5h5v5h-1V6.493a6.502 6.502 0 0 0 2.504 12.5a6.5 6.5 0 0 0 1.496-12.827V5.142A7.5 7.5 0 1 1 7.744 6H4.996V5z" fill="#fff" /></svg>
                                    <div className="new_box">
                                      <h3>{status_name}</h3>
                                      <h2>{value.status_count}</h2>
                                    </div>
                                  </a>
                                </li>
                              </Link>
                            );
                          })}
                        </ul>
                        <hr />
                        <br />
                      </div>
                    </div>
                  </Collapse> : <React.Fragment>
                    <div className="top_set_new">


                      <Carousel className="px-2 main_slider">
                        {this.state.advertisements.map((advertisement) => (
                          <Carousel.Item >
                            <Slidimages onClick={() => window.open(advertisement.url)} text="" path={api_url.base_url + advertisement.image} />
                          </Carousel.Item>
                        ))}
                      </Carousel>

                      <div className="dashb_icons custom">
                        <ul>
                        <li className="bg-success"><a href="#"><span className="d-block">{this.state.pendingcod}</span>{lang.cashBalance}</a></li>
                          <li className="bg-info"><a href="#"><span className="d-block">{this.state.totalcod}</span>{lang.notDeliveredBalance}</a></li>
                        </ul>

                        <span className="hr"></span>

                        <ul>
                          <li className="w-100 bg-custom hover-pointer"><a onClick={(event) => {
                            event.preventDefault();
                            window.open(this.state.youtubeTutorialLink)
                          }} className="new">

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-film" viewBox="0 0 16 16">
                              <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z" />
                            </svg>

                            <span>{lang.tutorialVideos}</span>

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>

                          </a></li>
                        </ul>
                        {this.state.news ? <React.Fragment>
                          <span className="hr"></span>
                          <ul>
                            <li className="w-100 bg-light border position-relative overflow-hide hover-pointer" style={{padding: 0}} onClick={() => window.open(this.state.news.url)}>
                            <a style={{display: 'flex', alignItems: 'center'}}>

                              <span className="breaking">{lang.breakingNews}</span>

                              <marquee class="marquee" behavior="scroll" direction={ lang_name === 'english' ?  "left" : "right"}> {this.state.news.title}</marquee>
                            </a></li>

                          </ul>
                        </React.Fragment>
                          : ''}

                        <span className="hr"></span>
                        <ul onClick={this.toggleTodayOrders}>
                          <li className="w-100 bg-warning"><a onClick={preventDefault} href="#" className="new"><span>{lang.todayRecordedOrders}</span>

                            <i ref={this.todayOrderRef} class={"fa " + (this.state.todayOrdersSelected ? "fa-chevron-down" : "fa-chevron-right")}> </i>

                          </a></li>
                        </ul>

                      </div>
                    </div>



                    <div className="shipment_box_new contact_page dashboard_page">

                      <p>
                        <b>{lang.dashboard}</b> {lang.lets_get_a_quick}
                      </p>



                      <ul>

                        {(this.state.allStatus != '') ?
                          this.state.allStatus.map((value, index) => {
                            var status_name = value.status_name ? value.status_name : '';

                            lang_array.length > 0 && lang_array.map(trnas_val => {
                              if (trnas_val.keyword.toLowerCase() == status_name.toLowerCase()) {
                                status_name = trnas_val.translation;
                              }
                            })

                            return (
                              <Link key={index} onClick={(e) => this.moveToOrders(value.status_name)}>
                                <li >
                                  <a key={index} className="service_bg">
                                    <svg viewBox="0 0 24 24"><path d="M4.996 5h5v5h-1V6.493a6.502 6.502 0 0 0 2.504 12.5a6.5 6.5 0 0 0 1.496-12.827V5.142A7.5 7.5 0 1 1 7.744 6H4.996V5z" fill="#fff" /></svg>
                                    <div className="new_box">
                                      <h3>{status_name}</h3>
                                      <h2>{value.status_count}</h2>
                                    </div>
                                  </a>
                                </li>
                              </Link>
                            );
                          })
                          :
                          <img className="loader_onorder" src={loader} alt="Loading data" />
                        }
                        {
                          this.state.loading ?
                            ''
                            :
                            <React.Fragment>
                              <li>
                                <a className="rate_bg">
                                  <svg viewBox="0 0 24 24"><path d="M4 3a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-1.77A3 3 0 0 0 21 15v-5a3 3 0 0 0-1-2.23V6a3 3 0 0 0-3-3H4zm0 1h13a2 2 0 0 1 2 2v1.174A3 3 0 0 0 18 7h-6a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h6a3 3 0 0 0 1-.174V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm8 4h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm2.5 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3z" fill="#fff" /></svg>
                                  <div className="new_box">
                                    <h3>{lang.pending_cod_amount}</h3>
                                    <h2>{this.state.pendingcod}</h2>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a className="rate_bg">
                                  <svg viewBox="0 0 24 24"><path d="M4 3a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-1.77A3 3 0 0 0 21 15v-5a3 3 0 0 0-1-2.23V6a3 3 0 0 0-3-3H4zm0 1h13a2 2 0 0 1 2 2v1.174A3 3 0 0 0 18 7h-6a3 3 0 0 0-3 3v5a3 3 0 0 0 3 3h6a3 3 0 0 0 1-.174V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm8 4h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm2.5 2a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3z" fill="#fff" /></svg>
                                  <div className="new_box">
                                    <h3> {lang.total_cod}</h3>
                                    <h2>{this.state.totalcod}</h2>
                                  </div>
                                </a>
                              </li>
                            </React.Fragment>
                        }

                      </ul>
                    </div>
                  </React.Fragment>}</React.Fragment>
              }

            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}
export default Home;
