import React from 'react';

const Slidimages = ({path}) => {
  return (
    <div className="carousel-image">
      <img className="w-100 hover-pointer" src={path} alt="Carousel Image" />
    </div>
  );
};

export default Slidimages;
