export const kurdish = {
    tracking_history: "بەدواداچوون",
    busingess_dashboard: "پانەلی بازرگانی",
    enter_your_tracking_code: "کۆدی پۆستەکەت داخل بکە",
    track_order: "بەدواداچوون بکە",
    get_quote: "نرخ وەربگرە",
    contact_us: "پەیوەندیمان پێوەبکە",
    service_type: "جۆری خزمەتگوزاری ",
    city_area: "شار/ناوچە",
    name: "ناو ",
    phone: "ژمارەی تەلەفۆن",
    phone_number: "مارەی موبایل",
    address: "ناونیشان",
    pickup_address: "ناونیشانی هەڵگرتن",
    previous: "پێشتر",
    next: "دواتر",
    delivery_detail: "وردەکاری گەیاندن",
    select: "دیاربکە",
    areas: "ناوچەکان",
    receiver_name: "ناوی وەرگر",
    receiver_phone: "ژمارە موبایلی وەرگر",
    receiver_address: "ناونیشانی وەرگر",
    shipment_details: "وردەکاری پۆستە",
    item_detail: "وردەکاری بەرهەم",
    details: "وردەکاری",
    special_instuction: "تێبینی تایبەت",
    weight: "کێش (Kg)",
    no_of_pieces: "ژمارەی پارچەکان",
    cod_amount: "بڕی پارە",
    loading: "دابەزین...",
    submit: "بنێرە",
    home: "سەرەکی",
    booking: "تۆمارکردن",
    order: "داواکاری",
    orders: "داواکاریەکان",
    pickup_detail: "وردەکاری هەڵگرتن",
    enter_your_name: "ناوت داخل بکە",
    enter_your_phone: "ژمارەی موبایل",
    your_message: "نامەکەت",
    sign_up: "ناو تۆمارکردن",
    personal_info: "زانیاری کەسی",
    company_name: "ناوی کۆمپانیا",
    person_of_contact: "کەسی بەرپرس",
    email: "ئیمێل",
    select_city: "شار دیاربکە",
    cnic: "ژمارەی ناسنامە",
    cnic_copy: "کۆپی ناسنامە",
    bank_information: "زانیاری بانک",
    bank_name: "ناوی بانک",
    account_title: "ناوی حساب",
    account_number: "ژمارەی حساب",
    branch_name: "ناوی لق",
    branch_code: "کۆدی لق",
    swift_code: "کۆدی سویفت",
    iban: "IBAN",
    password: "وشەی نهێنی",
    confirm_password: "دڵنیابوون لە وشەی نهێنی",
    dashboard: "پەنەلی سەرەکی",
    lets_get_a_quick: "زانیاریە سەرەکیەکان",
    lets_get_a_quick_today: "ڕاسته‌وخۆی ئه‌مڕۆی وێنەکان ببینه‌...",

    pending_cod_amount: "پارەدانی پێویست",
    total_cod: "کۆی پارەی داواکاریەکان",
    lets_start: "با دەست پێبکەین",
    plz_enter_requested_info: "تکایە زانیاریە پێویستەکان داخل بکە",
    sign_in: "داخل بە",
    dont_have_accunt: "حسابت نیە؟",
    taslim_net: "Taslim.net",
    tracking_no: "ژمارەی پۆستە",
    sender_information: "زانیاری نێردەر",
    receiver_information: "زانیاری وەرگر",
    order_iformation: "زانیاری داواکاریەکان",
    date: "بەروار",
    status: "دۆخ",
    order_list: "لیستی داواکاریەکان",
    pickup_city: "شاری هەڵگرتن",
    account_name: "ناوی حساب",
    bussiness_name: "ناوی بازرگانی",
    destination: "شوێنی مەبەست",
    parcel_weight: "کێشی پۆستە",
    delivery_fees: "کرێی گەیاندن",
    amount: "بڕی پارە",
    no_record_found: "هیچ تۆمارێک نەبوو",
    pickup_city_select: "شاری هەڵگرتن دیاربکە",
    rate_transit: "نرخەکان و گەیاندن",
    select_service_type: "جۆری خزمەتگوزاری دیاربکە",
    select_distination: "شاری گەیاندن دیاربکە",
    already_account: "خۆت حسابت هەیە؟",
    log_in: "داخل بە",
    soko_joint: "SokoJoint",
    to_me: "بۆ من",
    from_me: "لە منەوە",
    rates_transit: "نرخ و گەیاندن",
    pickup_request: "داواکاری هەڵگرتن",
    track_shipment: "بەدواداچونی پۆستە",
    track_ship_info: "بەدواداچونی زانیاری پۆستە",
    tracking_result: "ئەنجامی بەدواداچوون",
    shipper_info: "زانیاری گەیەنەر ",
    shipper: "گەیەنەر",
    origin: "سەرچاوە",
    consigne_info: "زانیاری وەرگر",
    save: "بیپارێزە",
    track_info: "زانیاری بەدواداچوون",
    tracking_list: "لیستی بەدواداچوون",
    track_your_pakage: "بەدواداچوون بۆ پۆستەکەت بکە",
    track_up_to: "بەدواداچوون بۆ ١٠ پۆست بکە، بە فاریزە لێکیان جیا بکەوە",
    notifications: "ئاگاداریەکان",
    tutorialVideos: "ڤیدیۆی فێرکاری",
    breakingNews: "هەواڵەکان",
    cashBalance: "پارەی گەیشتووەکان",
    notDeliveredBalance: "پارەی نەگەیشتووەکان",
    todayRecordedOrders: "پۆستە تۆمارکراوەکانی ئەمڕۆ",
    orderDetailMessageTemplate: `سڵاو بەڕێز
لە تەسلیم پۆست بەریدێکت بۆ تۆمارکراوە بە ژمارە موبایلی بەڕێزت بە تراک ئایدی {track_no}
ژمارەی ئەو شۆفێرەی بەریدەکەت بۆ دەهێنێت {phone_no} 
ڕەوشی بەریدەکەت {current_status} 
کۆی بەهای بەریدەکەت بریتیە لە {cod_amount} بۆ ناونیشانی بەڕێزت {address}  
بۆ ناردنی لۆکەیشنی خۆت تا بە زووترین کات پۆستەکە بگاتە دەستت تکایە ئەم لینکە کلیک بکە {address_url}`,

    "messageCopied": "پیام کاپی کرا بۆ خشتەی هەڵگرتن.",
    "location": "شوێن",
    "Supervisor": "سوپرڤایزەر",
    "createdAt": "دروستکراوە لە",
    "updatedAt": "نوێکرایەوە لە"
}
