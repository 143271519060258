import React, { useEffect, useState } from 'react';
import { Link,  } from 'react-router-dom';
import {english} from '../component/English';
import {api_url} from '../Config';

const Footer = (props) => {
  let lang_name = localStorage.getItem('lang_name');
  if (lang_name ==null) {
    localStorage.setItem('lang',JSON.stringify(english));

    localStorage.setItem('lang_name','english');
  }
  const lang =JSON.parse(localStorage.getItem("lang"));
  const [number, setNumber] = useState('');
  useEffect(() => {
    var WHATSAPPURL = api_url.base_url + 'customer_api.php?type=whatsappNo';
    
  fetch(WHATSAPPURL)
      .then(res => res.json())
      .then(result => {
        setNumber("https://wa.me/"+result.number)
      })
  }, [])
    return (


        <div className="bottom_bar">
    <ul style={{display: 'flex'}}>
      <li id="home_icon">
        <Link to="/">
         <svg aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#000"/></svg>
        <b>{lang.home}</b>
        </Link>
      </li>
      <li>
       <Link to="/booking">

          <svg viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#000"/></svg>
        <b>{lang.booking}</b>
       </Link>
      </li>
      <li>
        <Link to="/orders">
           <svg viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#000"/></svg>
        <b>{lang.order_list}</b>
        </Link>
      </li>

      <li>
        <Link to="#">
          
           <svg fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16"> <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/> </svg>
        <b>{lang.notifications}</b>
        </Link>
      </li>
    </ul>
     <div className="whatsapp">
        <a href={number} target="_blank"><i className="fa fa-whatsapp"></i></a>
      </div>
    </div>
    
    )
}

export default Footer;
