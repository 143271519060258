export const arabic = {
  tracking_history: "سجل التتبع",
  busingess_dashboard: "لوحة معلومات الأعمال",
  enter_your_tracking_code: "أدخل رمز التتبع الخاص بك",
  track_order: "ترتيب المسار",
  get_quote: "الحصول على الاقتباس",
  contact_us: "اتصل بنا",
  service_type: "نوع الخدمة",
  city_area: "المنطقة/المدينة",
  name: "الاسم",
  phone: "الهاتف",
  phone_number: "رقم الهاتف",
  address: "العنوان",
  pickup_address: "عنوان الاستلام",
  previous: "السابق",
  next: "التالي",
  delivery_detail: "تفاصيل التوصيل",
  select: "اختيار",
  areas: "المناطق",
  receiver_name: "اسم المستلم",
  receiver_phone: "رقم هاتف المستلم",
  receiver_address: "عنوان المستلم",
  shipment_details: "تفاصيل الشحنة",
  item_detail: "تفاصيل العنصر",
  details: "تفاصيل",
  special_instruction: "تعليمات خاصة",
  weight: "الوزن (كجم)",
  no_of_pieces: "عدد القطع",
  cod_amount: "مبلغ الدفع عند الاستلام (COD)",
  loading: "جارٍ التحميل...",
  submit: "إرسال",
  home: "الصفحة الرئيسية",
  booking: "الحجز",
  order: "ترتيب",
  orders: "الطلبات",
  pickup_detail: "تفاصيل الاستلام",
  enter_your_name: "ادخل اسمك",
  enter_your_phone: "رقم الجوال",
  your_message: "رسالتك هنا",
  sign_up: "سجل الدخول",
  personal_info: "معلومات شخصية",
  company_name: "اسم الشركة",
  person_of_contact: "شخص للاتصال",
  email: "البريد الإلكتروني",
  select_city: "اختر المدينة",
  cnic: "الهوية الوطنية (CNIC)",
  cnic_copy: "نسخة الهوية الوطنية (CNIC)",
  bank_information: "معلومات البنك",
  bank_name: "اسم البنك",
  account_title: "اسم الحساب",
  account_number: "رقم الحساب",
  branch_name: "اسم الفرع",
  branch_code: "رمز الفرع",
  swift_code: "الرمز السريع (Swift Code)",
  iban: "الرقم الدولي المصرفي (IBAN)",
  confirm_password: "تأكيد كلمة المرور",
  dashboard: "لوحة التحكم",
  lets_get_a_quick: "لنحصل على نظرة سريعة...",
  lets_get_a_quick_today: "لنحصل على نظرة سريعة على الطلبات اليوم...",

  pending_cod_amount: "مبلغ الدفع عند التسليم المعلق",
  total_cod: "إجمالي مبلغ الدفع عند التسليم (COD)",
  lets_start: "لنبدأ",
  plz_enter_requested_info: "يرجى إدخال المعلومات المطلوبة.",
  sign_in: "تسجيل الدخول",
  dont_have_accunt: "ليس لديك حساب؟",
  taslim_net: "تسليم.نت",
  tracking_no: "رقم التتبع",
  sender_information: "معلومات المرسل",
  receiver_information: "معلومات المستلم",
  order_iformation: "معلومات الطلب",
  date: "التاريخ",
  status: "الحالة",
  order_list: "قائمة الطلبات",
  pickup_city: "مدينة الاستلام",
  account_name: "اسم الحساب",
  bussiness_name: "اسم العمل",
  destination: "الوجهة",
  parcel_weight: "وزن الطرد",
  delivery_fees: "رسوم التوصيل",
  amount: "المبلغ",
  no_record_found: "لم يتم العثور على سجلات",
  pickup_city_select: "الرجاء اختيار مدينة الاستلام",
  rate_transit: "الأسعار والعبور",
  select_service_type: "الرجاء اختيار نوع الخدمة",
  select_distination: "الرجاء اختيار مدينة الوجهة",
  already_account: "هل لديك حساب بالفعل؟",
  log_in: "تسجيل الدخول",
  soko_joint: "سوكو جوينت",
  to_me: "إلىّ",
  from_me: "منّي",
  rates_transit: "الأسعار والعبور",
  pickup_request: "طلب الاستلام",
  track_shipment: "تتبع الشحنة",
  track_ship_info: "معلومات تتبع الشحنة",
  tracking_result: "نتائج التتبع",
  shipper_info: "معلومات المرسل",
  shipper: "المرسل",
  origin: "المصدر",
  consigne_info: "معلومات المستلم",
  save: "حفظ",
  track_info: "معلومات التتبع",
  tracking_list: "قائمة التتبع",
  track_your_package: "تتبع طردك هنا",
  track_up_to: "تتبع ما يصل إلى 10 أرقام في وقت واحد. افصل بينها بفاصلة (,)",

  notifications: "التنبيهات",
  tutorialVideos: "فيديو تعليمي",
  breakingNews: "الأخبار",

  cashBalance: "الرصيد النقدي",
  notDeliveredBalance: "الرصيد الغير واصل",
  todayRecordedOrders: "الطلبات المسجلة لهذا اليوم",

  orderDetailMessageTemplate: `مرحباً عزيزي
تم تسجيل طلب في شركة تسليم للتوصيل السريع علی هذا الرقم برقم الشحنة {track_no}
رقم المندوب الذي يوصل اليك الطلب {phone_no} 
حالة طلبك {current_status}
والسعر الكلي لطلبك هو {cod_amount} وعنوانكم هو {address}  
لإرسال موقعك (لوكيشن) حتى يصل إليك البريد في أسرع وقت ممكن الرجاء الضغط على هذا الرابط {address_url}`,
"messageCopied": "تم نسخ الرسالة إلى الحافظة.",
"location": "الموقع",
"Supervisor": "المشرف",
"createdAt": "تم الإنشاء في",
"updatedAt": "تم التحديث في",
}
